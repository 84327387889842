// const bURL = 'http://127.0.0.1:8000/'
const bURL = 'https://video.mightytech.cn/'
// const bURL = 'http://8.141.81.165:8080/'
// const bURL = 'http://143.64.225.207'

import axios from 'axios';
const excel_url = bURL +'uploadExcel'

async function createAxiosInstance() {
  const instance = axios.create({
    baseURL: bURL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return instance;
}

async function get(url, params) {
  const instance = await createAxiosInstance();
  return instance.get(url, { params });
}

async function post(url, data) {
  const instance = await createAxiosInstance();
  return instance.post(url, data);
}

async function put(url, data) {
  const instance = await createAxiosInstance();
  return instance.put(url, data);
}

async function del(url) {
  const instance = await createAxiosInstance();
  return instance.delete(url);
}

export { get, post, put, del, excel_url };