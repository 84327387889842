// import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: function () {
//       return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//     }
//   }
// ]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

// export default router
import { createWebHashHistory, createRouter } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/quality',
    name: 'quality',
    component: () => import(/* webpackChunkName: "about" */ '../views/qualityInspection.vue')
  },
  {
    path: '/toViews',
    name: 'toViews',
    component: () => import(/* webpackChunkName: "about" */ '../views/desktop.vue')
  },
  {
    path: '/errViews',
    name: 'errViews',
    component: () => import(/* webpackChunkName: "about" */ '../views/errorVideoViewingPage.vue')
  },
  {
    path: '/secondary',
    name: 'secondary',
    component: () => import(/* webpackChunkName: "about" */ '../views/secondaryInspection.vue')
  },
  {
    path: '/sql',
    name: 'sql',
    component: () => import(/* webpackChunkName: "about" */ '../views/pushtwoqualit.vue')
  },
  {
    path: '/AIqual',
    name: 'AIqual',
    component: () => import(/* webpackChunkName: "about" */ '../views/aIQualityInspection.vue')
  },
  {
    path: '/xiaowu',
    name: 'xiaowu',
    component: () => import(/* webpackChunkName: "about" */ '../views/xiaowu_redis.vue')
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;