<!-- 在Vue中创建登录注册响应式页面，并进行美化 -->
<template>
  <div class="container">
    <h2>{{ title }}</h2>
    <form @submit.prevent="isLogin ? login : register">
      <label v-if="isLogin" for="username"></label>
      <input v-if="isLogin" type="text" id="username" v-model="username" required placeholder="用户名:">
      <label v-if="isLogin" for="password"></label>
      <input v-if="isLogin" type="password" id="password" v-model="password" required placeholder="密码:">
      <label v-if="!isLogin" for="newUsername"></label>
      <input v-if="!isLogin" type="text" id="newUsername" v-model="newUsername" required placeholder="新用户名:">
      <label v-if="!isLogin" for="newPassword"></label>
      <input v-if="!isLogin" type="password" id="newPassword" v-model="newPassword" required placeholder="新密码:">
      <label v-if="!isLogin" for="targetNumber"></label>
      <input v-if="!isLogin" type="target" id="targetNumber" v-model="targetNumber" required placeholder="目标数量:">
      <label v-if="!isLogin" for="registrationCode"></label>
      <input v-if="!isLogin" type="target" id="registrationCode" v-model="registrationCode" required placeholder="注册码:">
      <button type="submit" @click="() => isLogin ? login() : register()">{{ isLogin ? '登录' : '注册' }}</button>
    </form>
    <button @click="toggleForm">{{ isLogin ? '去注册' : '返回登录' }}</button>
    <el-button @click="qualityInspectionLogin()" >质检登录</el-button>
    <p style="margin: 0;padding: 0;"><el-button @click="secondaryLogin()">二次质检</el-button></p>
    <el-link type="success" @click="deView()"> 视图 </el-link>
    <el-link type="danger"  @click="To_err()"> 错误 </el-link>
    <el-link type="primary" @click="To_sql()"> SQL </el-link>
    <el-link type="primary" @click="To_AIqual()">AI质检</el-link>
    <el-link type="primary" @click="To_wu()">吴</el-link>
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import { get, post, put, del } from '@/utils/request';
export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
    data() {
    return {
      title: "登录",
      isLogin: true,
      username: "",
      password: "",
      newUsername: "",
      newPassword: "",
      targetNumber:"",
      registrationCode:"",
    };
  },
  methods: {
    toggleForm() {
      this.title = this.isLogin ? "注册" : "登录";
      this.isLogin = !this.isLogin;
    },
    deView(){  // 去展示数据界面
      this.$router.push({ path: "/toViews"});
    },
    To_err(){  // 去展示数据界面
      this.$router.push({ path: "/errViews"});
    },
    To_sql(){
      this.$router.push({ path: '/sql'})
    },
    To_AIqual(){
      this.$router.push({ path: '/AIqual'})
    },
    To_wu(){
      this.$router.push({ path: '/xiaowu'})
    },
    login() {
      post('/login', {
            username: this.username,
            password: this.password
          })
        .then((response) => {
          console.log(response.data);
          if (response.data.code == 0){
              // 在Vue中使用路由跳转
              this.$router.push({ path: '/about', query: { user_id: response.data.user_id } });
              }else{
                alert("登陆失败")
              }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    qualityInspectionLogin(){
      post('/qualityInspection', {
            username: this.username,
            password: this.password
          })
        .then((response) => {
          console.log(response.data);
          if (response.data.code == 0){
              // 在Vue中使用路由跳转
              this.$router.push({ path: '/quality', query: { user_id: response.data.user_id } });
              }else{
                alert(response.data.errmsg)
              }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    secondaryLogin(){
      post('/qualityInspection', {
            username: this.username,
            password: this.password
          })
        .then((response) => {
          console.log(response.data);
          if (response.data.code == 0){
              // 在Vue中使用路由跳转
              this.$router.push({ path: '/secondary', query: { user_id: response.data.user_id } });
              }else{
                alert(response.data.errmsg)
              }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  register() {
    post('/register', {
            username: this.newUsername,
            password: this.newPassword,
            dailyGoals:this.targetNumber,
            registrationCode:this.registrationCode,
          })
        .then((response) => {
          console.log(response.data);
          if (response.data.code == 0){
              this.isLogin=true;
            }else{
              alert("注册失败-"+response.data.errmsg);
            }
        })
        .catch((error) => {
          console.error(error);
        });
      } 
}
}
</script>
<style>
.el-link {
    margin-left: 10px;
    margin-right: 10px;
}
.container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input {
  width: 100%;
  padding: 13px;
  margin: 5px 0;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid  #ccc;
}

button {
  width: 70%;
  padding: 10px;
  margin: 10px 0;
  margin-right: 5%;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* float: left; */
  /* overflow: hidden; */
  left: -20px;

}

button:hover {
  background-color: #0056b3;
}

.state {
  width: 20%;
}
</style>